import * as adminActionTypes from './adminActionTypes';
import { createReducer } from 'util/Helpers';

import { adminInitialState } from './adminInitialState';

const triggerRequest = (state) => {
  return state + 1;
};

const endRequest = (state) => {
  return state <= 0 ? 0 : state - 1;
};

const allProvidersDetail = (state, action) => {
  if (action.payload) {
    return Object.assign(action.payload);
  }
  return state;
};

const fetchAdminReports = (state, action) => {
  return Object.assign(action.payload);
};

// TODO remove this
const fetchAdminReportsOriginal = (state, action) => {
  return Object.assign(action.payload);
};

const fetchAdminReportDetails = (state, action) => {
  if (action.payload) {
    return Object.assign({}, state, action.payload);
  }
  return {};
};

const clearAdminReportDetails = () => {
  return {};
};

const fetchAllAgents = (state, action) => {
  if (action.payload) {
    return Object.assign(action.payload);
  }
  return {};
};

const fetchPenaltyDetails = (state, action) => {
  if (action.payload) {
    return {
      ...state,
      order: {
        ...state.order,
        cancellationCharges: action.payload.cancellationCharges,
        nonRefundableCharges: action.payload.nonRefundableCharges,
      },
    };
  }
  return {};
};

const fetchReIssuanceDetail = (state, action) => {
  if (action.payload) {
    return Object.assign(action.payload);
  }
  return {};
};

const paymentReducer = createReducer(
  {},
  {
    [adminActionTypes.GET_PROVIDERS]: allProvidersDetail,
  }
);
const requestReducer = createReducer(
  {},
  {
    [adminActionTypes.TRIGGER_REQUEST]: triggerRequest,
    [adminActionTypes.END_REQUEST]: endRequest,
  }
);
const adminReportReducer = createReducer(
  {},
  {
    [adminActionTypes.ADMIN_REPORTS]: fetchAdminReports,
  }
);
const adminReportOriginalReducer = createReducer(
  {},
  {
    [adminActionTypes.ADMIN_REPORTS_ORIGINAL]: fetchAdminReportsOriginal,
  }
);

const adminReportDetailsReducer = createReducer(
  {},
  {
    [adminActionTypes.ADMIN_REPORT_DETAILS]: fetchAdminReportDetails,
    [adminActionTypes.ORDER_PENALTIES]: fetchPenaltyDetails,
    [adminActionTypes.CLEAR_ADMIN_REPORT]: clearAdminReportDetails,
  }
);
const adminHotelReportDetailsReducer = createReducer(
  {},
  {
    [adminActionTypes.ADMIN_HOTEL_REPORT_DETAILS]: fetchAdminReportDetails,
    [adminActionTypes.CLEAR_ADMIN_REPORT]: clearAdminReportDetails,
  }
);
const adminTourReportDetailsReducer = createReducer(
  {},
  {
    [adminActionTypes.ADMIN_TOUR_REPORT_DETAILS]: fetchAdminReportDetails,
    [adminActionTypes.CLEAR_ADMIN_REPORT]: clearAdminReportDetails,
  }
);
const adminBusReportDetailsReducer = createReducer(
  {},
  {
    [adminActionTypes.ADMIN_BUS_REPORT_DETAILS]: fetchAdminReportDetails,
    [adminActionTypes.CLEAR_ADMIN_REPORT]: clearAdminReportDetails,
  }
);

const allAgentsReducer = createReducer(
  {},
  {
    [adminActionTypes.FETCH_ALL_AGENTS]: fetchAllAgents,
  }
);

const reIssuancDetailReducer = createReducer(
  {},
  {
    [adminActionTypes.GET_REISSUANCE_DETAILS]: fetchReIssuanceDetail,
  }
);

const AdminReducer = (state = adminInitialState, action) => ({
  // eslint-disable-next-line new-cap
  adminReports: adminReportReducer(state.adminReports, action),
  adminReportOriginal: adminReportOriginalReducer(state.adminReportOriginal, action),
  adminReportDetails: adminReportDetailsReducer(state.adminReportDetails, action),
  adminHotelReportDetails: adminHotelReportDetailsReducer(state.adminHotelReportDetails, action),
  adminTourReportDetails: adminTourReportDetailsReducer(state.adminTourReportDetails, action),
  adminBusReportDetails: adminBusReportDetailsReducer(state.adminBusReportDetails, action),
  allAgents: allAgentsReducer(state.allAgents, action),
  fetchCount: requestReducer(state.fetchCount, action),
  paymentProviders: paymentReducer(state.paymentProviders, action),
  reIssuanceDetail: reIssuancDetailReducer(state.reIssuanceDetail, action),
});

export default AdminReducer;
