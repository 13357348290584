import { TRAVELER_DETAIL_KEYS } from './formUtils/formConstants';

export const APP_TIMEZONE = 'Asia/Karachi';
export const PAKISTAN_TIMEZONE = 'Asia/Pakistan';
export const APP_COUNTRY = 'Pakistan';
export const APP_DATEFORMAT = 'DD MMM YYYY';
export const APP_MOBILE_DATEFORMAT = 'ddd DD/MM';
export const APP_TIMEFORMAT = 'HH:mm';
export const APP_DATETIMEFORMAT = 'DD MMM YYYY, HH:mm';
export const DATE_FRMT_DAY_DATE_TIME = 'dddd, DD MMMM YYYY, HH:mm';
export const DATE_FORMAT_READABLE = 'Do MMM YYYY';
export const DATE_FRMT_FOR_URL = 'YYYY-MM-DD';
export const DATE_FRMT_FOR_SEARCHBOX = 'DD/MM/YYYY';
export const DATE_FORMAT_JS_DATE = 'MM/DD/YYYY';
export const DATE_FRMT_DAY_DATE = 'dddd, DD MMMM YYYY';
export const DATE_FRMT_SHORT_DAY_DATE = 'ddd, DD MMMM YYYY';
export const DATE_FRMT_DOB_READABLE = 'MMMM D, YYYY';
export const DATE_FRMT_DAY_MONTH = 'ddd, Do MMM';
export const DATE_FRMT_DAY_MONTH_WITHOUT_TH = 'ddd, DD MMM';
export const DAY_MONTH_FORMAT = 'DD MMM';
export const DAY_MONTH_FRMT = 'dd-MMMM';
export const YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const YEAR_MONTH_DAY_WITH_TIME = 'YYYY-MM-DD HH:mm:ss';
export const DATE_MONTH_DAY = 'Do MMMM, dddd ';
export const BUS_TIME_FORMAT = 'hh:mm A';
export const BUS_DATE_FORMAT = 'DD-MMM-YYYY';
export const BUS_DATETIME_FORMAT = 'DD-MMM-YYYY, hh:mm A';

export const ONLINE_PAYMENT_URL = 'https://testsecureacceptance.cybersource.com/silent/pay';
export const AIRLINE_IMAGES_URL = 'https://s3.ap-south-1.amazonaws.com/st-airline-images/';

export const CURRENCY_PREFIX = 'Rs. ';

export const CYBERSRC_MERCHANT_ID = 'hbl_sastaticket';

export const RESPONSIVE_CONFIGS = {
  WEB: 'webConfig',
  MOBILE: 'mobileConfig',
};

export const PHONE_NUMBER_COUNTRIES = [
  'PK',
  'AU',
  'BH',
  'KW',
  'OM',
  'QA',
  'GB',
  'US',
  'CN',
  'IN',
  'AE',
  'CA',
  'DE',
  'MY',
  'FR',
  'NL',
  'SG',
  'IT',
  'TR',
  'NO',
  'JP',
  'ES',
  'TH',
  'ID',
  'SE',
  'IE',
  'SA',
];

export const SOCIAL_PROVIDERS = {
  FACEBOOK: 'f',
  GOOGLE: 'g',
};

export const POLLING_INTERVALS = {
  SHORT_INTERVAL: 2000,
  LONG_INTERVAL: 5000,
  TIMEOUT: 60000,
  POLLING_MULTIPLIER: 1.2,
};

export const TRIP_TYPES = {
  ONEWAY: 'ONEWAY',
  RETURN: 'RETURN',
  MULTI: 'MULTICITY',
};

export const DISPLAY_TRIP_TYPE = {
  DEPART: 'Departure',
  RETURN: 'Return',
  LEG: 'LEG',
};

export const TICKET_TYPES = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
};

export const LIST_TYPES = {
  INBOUNDS: 'inbounds',
  OUTBOUNDS: 'outbounds',
};

export const BTN_TYPES = {
  SIMPLE: 'simple',
  LADDA: 'ladda',
};

export const LOADER_TYPES = {
  PAGE: 'pageloader',
  SIMPLE: 'simpleloader',
};

export const VIEW_PORTS = {
  MOBILE: '(max-width : 767px)',
  DESKTOP: '(min-width : 768px)',
  ONLY_DESKTOP: '(min-width: 1010px)',
};

export const FILTERS = {
  AIRLINE: 'Airlines',
  AIRPORTS: 'Airports',
  ARRIVAL: 'Arrival',
  DEPART: 'Departure',
  MORE: 'More Filters',
  PRICE: 'Price',
  REFUND: 'Refund',
  SORT: 'Sort',
  STOPS: 'Stops',
  TRANSITS: 'Transits',
  TIME: 'Time',
};

export const SORTERS = {
  // Note: Order of key-values must be retained
  AIRLINE: 'Airline',
  DEPARTURE: 'Depart',
  ARRIVAL: 'Arrive',
  STOPS: 'Stops',
  DURATION: 'Duration',
  PRICE: 'Price',
  RATING: 'Rating',
};

export const MOBILE_SORTERS = {
  PRICE: 'Price',
  DEPARTURE: 'Depart',
  ARRIVAL: 'Arrive',
  DURATION: 'Duration',
};

export const SORT_ORDERS = {
  [SORTERS.PRICE]: {
    asc: 'Lowest',
  },
  [SORTERS.DEPARTURE]: {
    asc: 'Earliest',
    desc: 'Latest',
  },
  [SORTERS.ARRIVAL]: {
    asc: 'Earliest',
    desc: 'Latest',
  },
  [SORTERS.DURATION]: {
    asc: 'Shortest',
  },
};

export const ROUTE_PREFIX = '/air';
export const HOTELS_PREFIX = '/stays/';
export const HOTELS_PREFIX_V3 = '/stays/';
export const UMRAH_PREFIX = '/umrah';
export const TOUR_PREFIX = '/tours';
export const BUS_PREFIX = '/bus/';
export const DSM_ROUTE_PREFIX = '/air/v2';

export const ACCOUNT_ROUTE_PREFIX = '/air/account';
export const ACCOUNT_HOTEL_PREFIX = `${HOTELS_PREFIX}account`;
export const BOOKING_ROUTE_PREFIX = '/air/booking';
export const HOTEL_BOOKING_ROUTE_PREFIX = `${HOTELS_PREFIX_V3}booking`;
export const BUS_BOOKING_ROUTE_PREFIX = `${BUS_PREFIX}booking`;
export const HOTEL_BOOKING_PREFIX = '/hotels/booking';
export const ACCOUNT_BUS_PREFIX = `${BUS_PREFIX}account`;
export const ACCOUNT_TOUR_PREFIX = `${TOUR_PREFIX}account`;

export const CABIN_TYPES = {
  ECONOMY: {
    code: 'Y',
    label: 'Economy',
  },
  ECONOMYPREMIUM: {
    code: 'W',
    label: 'Premium Economy',
  },
  BUSINESS: {
    code: 'C',
    label: 'Business',
  },
  FIRST: {
    code: 'P',
    label: 'First Class',
  },
};

export const TRAVELER_TYPES = {
  ADULTS: {
    label: 'Adult',
    code: 'ADT',
    queryPropertyName: 'numAdult',
    pricePropName: 'adult_total_fare',
    propName: 'adultTotalFare',
  },
  CHILDREN: {
    label: 'Child',
    code: 'CNN',
    queryPropertyName: 'numChild',
    pricePropName: 'child_total_fare',
    propName: 'childTotalFare',
  },
  INFANTS: {
    label: 'Infant',
    code: 'INF',
    queryPropertyName: 'numInfant',
    pricePropName: 'infant_total_fare',
    propName: 'infantTotalFare',
  },
};

export const FLEXIBILITY_TYPES = {
  ONEDAY: '+/-1 DAYS',
  TWODAY: '+/-2 DAYS',
  THREEDAY: '+/-3 DAYS',
};

// NOTE: Here order of methods does matter. Follow the order to display in paymentSideBar.
export const PAYMENT_METHODS = {
  TRANSFER: {
    name: 'Bank Transfer',
    key: 'BANK_TRANSFER',
  },
  ONLINE: {
    name: 'Debit / Credit Card',
    key: 'ONLINE',
  },
  LCL_BRC: {
    name: 'Sastaticket Branch',
    key: 'SASTATICKET_BRANCH',
  },
  EASYPAY: {
    name: 'Easypaisa',
    key: 'Easypaisa',
  },
  JAZZCASH: {
    name: 'JazzCash',
    key: 'JazzCash',
  },
  HBLDT: {
    name: 'HBL Direct Transfer',
    key: 'HBL_KONNECT',
  },

  PAYPRO: {
    name: 'PayPro',
    key: 'PAYPRO',
  },

  PAYPAK: {
    name: 'PayPak Debit Card',
    key: 'PAYPAK',
  },
  UNIONPAY: {
    name: 'UnionPay ',
    key: 'UNIONPAY',
  },
  ADJUSTMENT: {
    name: 'Adjustment - Adjustment Transaction',
    key: 'ADJUSTMENT',
  },
  WALLET: {
    name: 'Sasta Wallet',
    key: 'WALLET',
  },
  LOYALTY: {
    name: 'Sasta Cash',
    key: 'LOYALTY',
  },
  NIFT: {
    name: 'NIFT ePay',
    key: 'NIFT',
  },
};

export const EASYPAY_PAYMENT_TYPES = {
  MOBILE: 'EASYPAY_MA',
  SHOP: 'EASYPAY_OTC',
};

export const JAZZCASH_PAYMENT_TYPES = {
  MOBILE: 'JAZZ_MWALLET',
  SHOP: 'JAZZ_OTC',
};

export const JAZZCASH_EASYPAY_CONTACT = 'mobileAccountNo';
export const PAYPRO = 'PayPro - Bank Transfer';

export const ONLINE_PAYMENT_TYPE = {
  CYBERSOURCE: 'HBL_CS',
};

export const PAYMENT_KEYS = {
  METHOD: 'method',
  DETAILS: 'details',
};
export const PAYMENT_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
};

export const LOCAL_BRANCH_TYPES = {
  CREDIT_CARD: 'creditcard',
  CASH: 'cash',
};

export const ORDER_STATUS = {
  EXPIRED: 'EXPIRED',
  HOLD: 'HOLD',
  NOT_PAID: 'NOTPAID',
  ORDER_ERROR: 'ERROR',
  ORDER_INITIATED: 'INITIATED',
  PARTIAL_PAID: 'PARITIALPAID',
  PAYMENT_AWAITING_APPROVAL: 'PAYMENTAWAITINGAPPROVAL',
  PAYMENT_PENDING: 'PENDING',
  PROCESSING_TICKET: 'PROCESSINGTICKET',
  TICKET_CANCELLED: 'CANCELLED',
  TICKET_ISSUED: 'TICKETISSUED',
  VOUCHER_ISSUED: 'VOUCHERISSUED',
  DELETED: 'DELETED',
};

export const WEGO_ORDER_STATUS = {
  CONFIRMED: 'CONFIRMED',
  PENDING: ORDER_STATUS.PAYMENT_PENDING,
  CANCELLED: ORDER_STATUS.TICKET_CANCELLED,
};

export const TRANSACTION_STATUS = {
  FAIL: 'fail',
  SUCCESS: 'success',
  PENDING: 'pending',
};

export const READABLE_ORDER_STATUS: { [key: string]: string } = {
  EXPIRED: 'Expired',
  HOLD: 'Hold',
  NOTPAID: 'Not Paid',
  ERROR: 'Error',
  INITIATED: 'Initiated',
  PARITIALPAID: 'Partially Paid',
  PAYMENTAWAITINGAPPROVAL: 'Payment Awaiting Approval',
  PENDING: 'Payment Pending',
  PROCESSINGTICKET: 'Processing Ticket',
  CANCELLED: 'Cancelled',
  TICKETISSUED: 'Ticket Issued',
  VOUCHERISSUED: 'Voucher Issued',
  DELETED: 'Voided',
};

export const TRX_STATUS = {
  INITIATED: 'INITIATED',
  CHECKOUT: 'CHECKOUT',
  VALIDATION: 'VALIDATION',
  PAID: 'PAID',
  ERROR: 'ERROR',
};

export const TITLES: { [key: string]: string } = {
  MR: 'Mr',
  MASTER: 'Master',
  MRS: 'Mrs',
  MS: 'Ms',
};

export const PARTS_OF_DAY = {
  MORNING: 'Morning',
  AFTERNOON: 'Afternoon',
  EVENING: 'Evening',
  ANY: 'Any',
};

// Charge Types
export const CHARGE_TYPES = {
  FIX: 'FIX',
  PERCENTAGE: 'PER',
};

export const PRINTABLE_TICKET_KEYS = {
  CONTENT: 'ticketContent',
  IFRAME: 'iframeContent',
};

// Google Analytics Events
export const ANALYTICS_EVENTS = {
  NAVIGATION: {
    __CATEGORY__: 'navigation',
    PAGE_LOAD: 'pageLoad',
  },
  SEARCH: {
    __CATEGORY__: 'search',
    ORIGIN: 'origin',
    DESTINATION: 'destination',
    TOGGLE_ROUND_TRIP: 'toggleRoundTrip',
    SUBMIT_FORM: 'submitForm',
  },
  HOTEL_SEARCH: {
    __CATEGORY__: 'hotelsearch',
    SUBMIT_FORM: 'hotelsearchsubmit',
    LOCATION: 'hotellocation',
    CHECK_IN: 'hotelcheckindate',
    CHECK_OUT: 'hotelcheckoutdate',
    TRAVELLER_COUNT: 'hoteltravellercount',
  },
  BUS_SEARCH: {
    __CATEGORY__: 'busSearch',
    SUBMIT_FORM: 'busSearchSubmit',
  },
  HOTEL_SEARCH_RESULTS: {
    __CATEGORY__: 'hotelsearchresults',
    SUBMIT_SEARCH: 'hotelmodifysearch',
    FEATURED_SORT: 'hotelsortfeatured',
    PRICE_SORT: 'hotelsortprice',
    RATING_SORT: 'hotelsortstarrating',
    SEARCH_NAME: 'hotelpropertynamesearch',
    FILTER_CLASS: 'hotelfilterpropertyclass',
    FILTER_PROPERTY_TYPE: 'hotelfilterpropertytype',
    FILTER_RANGE: 'hotelfilterpricerange',
    FILTER_NEIGHBORHOOD: 'hotelfilterneighborhood',
    FILTER_AMENITIES: 'hotelfilteramenities',
    FILTER_BOOKING_POLICY: 'hotelfilteramenities',
    FILTER_PAYMENT: 'hotelfilterpayment',
    SELECT_HOTEL: 'selecthotel',
    NO_HOTELS: 'nohotelsfound',
  },
  BUS_SEARCH_RESULTS: {
    __CATEGORY__: 'busSearchResults',
    SUBMIT_SEARCH: 'busModifySearch',
    SELECT_SEAT: 'busSelectSeats',
  },
  HOTEL_TRAVEL_BOOKING: {
    __CATEGORY__: 'hoteltravellerdetail',
    TRAVEL_DETAILS_COMPLETE: 'hoteltravellerdetailscomplete',
  },
  BUS_TRAVEL_BOOKING: {
    __CATEGORY__: 'busBookingReview',
    TRAVEL_DETAILS_COMPLETE: 'busTravellerDetail',
  },
  HOTEL_ROOMS: {
    __CATEGORY__: 'hotelroomresults',
    BACK_TO_PROPERTY: 'htbacktoproperty',
    ROOMS_VIEW_DETAIL: 'htviewroomdetails',
    HOTEL_INFO: 'htviewhotelinfo',
    AMENITIES: 'htviewamenities',
    HOTEL_LOCATION: 'htclickmap',
    SEARCH_ROOM: 'searchhotelroom',
    BOOK_ROOM: 'bookroom',
    ROOM_CLICK_DETAIL: 'htclickdetailsroom',
    SEE_MORE: 'htclickseemoreproperty',
    HOTEL_LOCATION2: 'htclickmap2',
  },
  HOTEL_PAYMENT: {
    __CATEGORY__: 'hotelpaymentcomplete',
    SUCCESS: 'hotelpaymentsuccess',
  },
  BUS_PAYMENT: {
    __CATEGORY__: 'busPayment',
    SUCCESS: 'busPaymentSuccess',
  },
  SEARCH_RESULTS: {
    __CATEGORY__: 'searchResults',
    CLICK_DETAILS: 'clickDetails',
    CHANGED_FILTER_AIRLINES: 'changedFilterAirlines',
    CHANGED_FILTER_STOPS: 'changedFilterStops',
    CLICKED_REFRESH_RESULTS: 'clickedRefreshResults',
    CLICKED_CHANGED_SEARCH: 'clickChangeSearch',
    NO_FLIGHTS_FOUND: 'noFlightsFound',
    PRE_REGISTRATION: 'Preregister',
  },
  MY_BOOKINGS: {
    __CATEGORY__: 'myBookings',
    CLICK_BOOKING_DETAILS: 'clickBookingDetails',
  },
  PROFILE: {
    __CATEGORY__: 'profile',
    SAVE_PROFILE: 'saveProfile',
  },
  AUTH: {
    __CATEGORY__: 'auth',
    LOGIN: 'login',
    REGISTER: 'register',
  },
  PAYMENTS: {
    __CATEGORY__: 'payment',
    SELECT_PAYMENT: 'selectPayment',
    FARE_RULES: 'fareRules',
    MAKE_PAYMENT: 'makePayment',
    BOOKING_EXPIRED: 'bookingExpired',
  },
  FLIGHT_BOOKING_REVIEW: {
    __CATEGORY__: 'bookingReview',
    CLICK_READ_MORE: 'clickReadMore',
    CLICK_FLIGHT_DETAILS: 'clickFlightDetails',
    CHANGE_FLIGHTS: 'changeFlights',
    CLICK_CONTINUE_TO_BOOKING: 'clickContinueToBooking',
  },
  BOOKING_PASSENGER_DETAILS: {
    __CATEGORY__: 'bookingPassengerDetails',
    LOGIN: 'login',
    REGISTER: 'register',
    UPDATE_CONTACT_FIRSTNAME: 'updateContactFirstName',
    UPDATE_CONTACT_LASTNAME: 'updateContactLastName',
    UPDATE_MOBILE_NUMBER: 'updateMobileNumber',
    UPDATE_EMAIL: 'updateEmail',
    UPDATE_TITLE: 'updateTitle',
    UPDATE_PASSENGER_FIRSTNAME: 'updatePassengerFirstName',
    UPDATE_PASSENGER_LASTNAME: 'updatePassengerLastName',
    UPDATE_PASSENGER_NATIONALITY: 'updatePassengerNationality',
    UPDATE_PASSENGER_DOB: 'updatePassengerDOB',
    UPDATE_PASSENGER_PASSPORT_NUMBER: 'updatePassengerPassportNumber',
    UPDATE_PASSENGER_PASSPORT_EXPIRY: 'updatePassengerPassportExpiry',
    UPDATE_PASSEGER_VISA_TYPE: 'updatePassengerVisaType',
  },
  FINALISE_BOOKING_DETAILS: {
    __CATEGORY__: 'finalisedBookingDetails',
    CLICK_BOOKING_DETAILS: 'clickBookingDetails',
    CLICK_MY_ITINERARY: 'clickMyItinerary',
    CLICK_CONTINUE: 'clickContinue',
  },
  BOOKING_SUMMARY: {
    __CATEGORY__: 'flights',
    FLIGHT_DETAILS: 'flightDetails',
  },
  MEMBER_MODULE: {
    LOGIN: {
      __CATEGORY__: 'verificationLogin',
      VERIFICATION_LOGIN: 'verificationLogin',
    },
    REGISTER: { __CATEGORY__: 'verificationRegister', VERIFICATION_REGISTER: 'verificationRegister' },
    OTP_ACTIONS: {
      OTP_REQUESTED: 'OTP requested',
      OTP_EXPIRED: 'OTP expired',
      OTP_RESEND: 'OTP resend',
      OTP_SUBMITTED: 'OTP submitted',
    },
    OTP_LABELS: {
      OTP_FAIL: 'OTP failed',
      OTP_SUCCESS: 'OTP successful',
    },
  },
};

export const GA_OFFLINE_PAYMENT_EVENT = {
  CONTEXT_KEYS: {
    PROTOCOL_VERSION: 'v',
    CLIENT_ID: 'cid',
    TRACK_TYPE: 't',
    DOCUMENT_LOCATION: 'dl',
    TRACKING_ID: 'tid',
    TRANSACTION_AFFILIATION: 'ta',
    TRANSACTION_TAX: 'tt',
    TRANSACTION_SHIPPING: 'ts',
    PRODUCT_ACTION: 'pa',
    PRODUCT1_NAME: 'pr1nm',
    PRODUCT_ID: 'pr1id',
    PRODUCT_PRICE: 'pr1pr',
    PRODUCT_BRAND: 'pr1br',
    PRODUCT_CATEGORY: 'pr1ca',
    PRODUCT_VARIANT: 'pr1va',
    PRODUCT_QUANTITY: 'pr1qt',
    EVENT_CATEGORY: 'ec',
    EVENT_ACTION: 'ea',
    EVENT_LABEL: 'el',
    NON_INTERACTIVE_HIT: 'ni',
    DEVICE_ID: 'device_id',
    WEBSITE_VERSION: 'website_version',
  },
};

// Facebook Pixel Events Names
export const FB_PIXEL_ANALYTICS_EVENTS = {
  CONTEXT_KEYS: {
    CONTENT_CATEGORY: 'content_category',
    CONTENT_IDS: 'content_ids',
    CONTENT_NAME: 'content_name',
    CONTENT_TYPE: 'content_type',
    CURRENCY: 'currency',
    VALUE: 'value',
    PRICE: 'searchPrice',
    IS_OFFLINE_METHOD: 'is_offline_method',
  },
  EVENT_NAMES: {
    search: 'Search',
    bookingReview: 'AddToCart',
    register: 'CompleteRegistration',
    finalisedBookingDetails: 'InitiateCheckout',
    payment: 'Purchase',
    viewContent: 'ViewContent',
  },
  KEYWORDS: {
    FLIGHT: 'flight',
    PRODUCT: 'product',
    PRODUCT_GROUP: 'product_group',
    CURRENCY: 'PKR',
    HOTEL: 'hotel',
  },
};

export const ANALYTICS_PROVIDERS = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  AMPLITUDE: 'amplitude',
};

export const TIME_RANGE = {
  departure: ['00:00 - 06:00', '06:00 - 12:00', '12:00 - 18:00', '18:00 - 24:00'],
  arrival: ['00:00 - 06:00', '06:00 - 12:00', '12:00 - 18:00', '18:00 - 24:00'],
};

export const FLIGHT_STATUS = {
  NONSTOP: 'Nonstop',
};

export const ROLE_PERMISSION = ['Agent'];
export const USER_ROLE = ['Partner'];
export const WALLET_USER_ROLES = ['Partner', 'Wallet Group'];

export const PROVIDER_FILTERS: { [key: string]: any } = {
  stuba: 'stuba',
  bedbank: 'bed_bank',
  daewoo: 'daewoo',
  kainattravels: 'kainattravels',
  skyways: 'skyways',
  Silkline: 'silkline',
  Fmbt: 'fmbt',
  BusBank: 'busbank',
};

export const LS_KEYS = {
  LAST_SEARCH: 'last_search',
  LAST_UMRAH_SEARCH: 'last_umrah_search',
  LAST_TOUR_SEARCH: 'last_tour_search',
  LAST_BUS_SEARCH: 'last_bus_search',
};

export const FEE_TYPE = {
  fixed: 'F',
  percentage: 'P',
};

export const FLIGHT_TYPE = {
  DOMESTIC: 'Domestic',
  INTERNATIONAL: 'International',
};

export const ENHANCED_ECOMMERCE_TYPES = {
  __CATEGORY__: 'setAction',
  ADD_PRODUCT: 'addProduct',
  ADD_TO_CART: 'addToCart',
  PRODUCT_CLICK: 'productClick',
  CHECKOUT: 'checkout',
  PURCHASE: 'purchase',
  DETAIL: 'detail',
  ADD: 'add',
  CLICK: 'click',
  PRODUCT_IMPRESSION: 'productImpression',
};

export const DEALS_TYPES = {
  SASTATICKET_DEALS: 'Sastaticket_Deals',
  DEALS_1111: '11.11_Deals',
};

export const SW_WHITE_LIST = ['v1/air/get_all_stops'];

export const SUPPORTED_ID_TOKEN_PROVIDER = [
  {
    uri: 'https://accounts.google.com',
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  },
];

export const SUPPORTED_AUTH_METHOD = ['https://accounts.google.com'];

export const HOTEL_INFO_NAVLINKS = [
  {
    label: 'Rooms',
    link: 'room-details-section',
  },
  {
    label: 'Hotel Information',
    link: 'property-information',
  },
  // {
  //   label: 'Policies',
  //   link: 'policies-section',
  // },
];
export const HOTEL_MOBILE_SORTERS = {
  FEATURED: {
    label: 'Featured',
    name: 'featured',
  },
  RECOMMENDED: {
    label: 'Recommended',
    name: 'recommended',
  },
  PRICE: {
    label: 'Price',
    name: 'price',
  },
  GUEST_RATING: {
    label: 'Guest Rating',
    name: 'rating',
  },
  DISTANCE_FROM_DOWN_TOWN: {
    label: 'Distance from downtown',
    name: 'distance_from_down_town',
  },
  PROPERTY_CLASS: {
    label: 'Property Class',
    name: 'property_class',
  },
  VACATION_RENTALS: {
    label: 'Vacation Rentals',
    name: 'apart_hotel',
  },
};

export const HOTEL_SEARCH_FORM = {
  CHECK_IN: 'Check-in',
  CHECK_OUT: 'Check-out',
};

export const HOTEL_CARD_CHIP = {
  COLORS: {
    blue: '#0054a0',
    green: '#016833',
  },
  BED_AND_BREAKFAST: {
    name: 'Bed and Breakfast',
    label: 'Breakfast Included',
  },
  FREE_AIRPORT_TRANSFER: {
    name: 'Free Airport Transfer',
    label: 'Free Airport Transfer',
  },
  FREE_CANCELLATION: {
    name: 'Free Cancellation',
    label: 'Free Cancellation',
  },
  NON_REFUNDABLE: {
    name: 'Non-Refundable',
    label: 'Non-Refundable',
  },
  REFUNDABLE: {
    name: 'Refundable',
    label: 'Refundable',
  },
};
export const HOTEL_STAY_BOUNDARY = {
  CHECK_IN: 'checkin',
  CHECK_OUT: 'checkout',
};

export const HOTEL_DESKTOP_SORTERS: { [key: string]: any } = {
  FEATURED: {
    label: 'Featured',
    name: 'featured',
    options: [{ label: 'Featured', value: 'DESC' }],
  },
  RECOMMENDED: { label: 'Recommended', name: 'recommended', options: [] },
  PRICE: {
    label: 'Price',
    name: 'price',
    options: [
      { label: 'Price (Low to High)', value: 'ASC' },
      { label: 'Price (High to Low)', value: 'DESC' },
    ],
  },
  RATING: {
    label: 'Rating',
    name: 'rating',
    options: [
      { label: 'Stars (5 to 1)', value: 'DESC' },
      { label: 'Stars (1 to 5)', value: 'ASC' },
    ],
  },
  GUEST_RATING: { label: 'Guest Rating', name: 'rating', options: [] },
  DISTANCE_FROM_DOWN_TOWN: { label: 'Distance', name: 'distance_from_down_town', options: [] },
};

export const HOTEL_MORE_DETAILS_NAVLINKS = [
  {
    label: 'Hotel overview',
    link: 'hotel-overview-section',
  },
  {
    label: 'Check-in and Check-out',
    link: 'checkin-and-checkout-section',
  },
  {
    label: 'Room',
    link: 'room-details-section',
  },
  {
    label: 'Policies',
    link: 'policies-section',
  },
  {
    label: 'Price summary',
    link: 'price-summary-section',
  },
  {
    label: 'Help',
    link: 'help-section',
  },
];

export const FEATURES = {
  HOTELS: 'hotels',
  UMRAH: 'umrah',
  TOURS: 'tours',
  BUS: 'bus',
  EMI: 'easy-money-installment',
  VERIFICATION_BANNER: 'verification-banner',
  CARD_DISCOUNTS: 'cardDiscounts',
  BOOKING_CANCELLATION: 'bookingCancellation',
  CALENDAR_FARE: 'calendarFare',
  BAGGAGE_FARE_OPTIONS: 'baggageFareOptions',
  TRAVEL_INSURANCE: 'travelInsurance',
  AIRPORT_TRANSFER: 'airportTransfer',
  TICKET_RE_ISSUE: 'ticketReIssue',
  FARE_OPTIONS_SERENE: 'sereneFareOptions',
  AIR_V2: 'air-v2',
  AIR_V1_HEADER_STYLE: 'airV1HeaderStyle',
  MODIFY_BOOKING: 'modifyBooking',
  SASTA_WALLET: 'sastaWallet',
  DOMESTIC_TRAVEL_CNIC: 'domesticTravelCnic',
  AMPLITUDE_ENABLED: 'amplitudeEnabled',
};

export const PAYMENT_OPTIONS = [
  {
    title: 'Pay now',
    conditions: [
      'More ways to pay: You can use partial payments via multiple payment methods to pay',
      'You can use a valid Sastaticket.pk voucher to avail deals and discounts',
    ],
  },
  {
    title: 'Pay at Hotel',
    conditions: [
      'You pay a small deposit now to hold your booking',
      'You will pay the remaining balance directly to hotel at check-in',
    ],
  },
];

export const HOTEL_PAYMENT_OPTIONS = {
  AT_HOTEL: 'AT_HOTEL',
  AT_WEB: 'AT_WEB',
};

export const WEB_ENGAGE_DATA: { [key: string]: any } = {
  TRIP_TYPE: {
    ONEWAY: 'One-way',
    RETURN: 'Round-trip',
    MULTICITY: 'Multi-city',
  },
};

//5 minutes otp expiry time
export const OTP_EXPIRY_TIMEOUT = 30000;
export const WALLET_OTP_EXPIRY_TIMEOUT = 300;
//last card number is a testing card
export const BIN_NUMBERS = [
  '490288',
  '403935',
  '403906',
  '490287',
  '494123',
  '490286',
  '494122',
  '542831',
  '518496',
  '547175',
  '524643',
  '517420',
  '539160',
  '536632',
  '621657',
  '490471',
  '421485',
  '536619',
  '411111',
];

export const HBL_AUTO_DISCOUNT_PROMOCODE = 'HBLDISCOUNT';

export const ORDER_STATUS_FILTERS = [
  { label: 'INITIATED', value: ORDER_STATUS.ORDER_INITIATED },
  { label: 'PAYMENT PENDING', value: ORDER_STATUS.PAYMENT_PENDING },
  { label: 'PROCESSING TICKET', value: ORDER_STATUS.PROCESSING_TICKET },
  { label: 'ORDER EXPIRED', value: ORDER_STATUS.EXPIRED },
  { label: 'ORDER WITH ERROR', value: ORDER_STATUS.ORDER_ERROR },
  { label: 'ORDER CANCELLED', value: ORDER_STATUS.TICKET_CANCELLED },
  { label: 'TICKET ISSUED', value: ORDER_STATUS.TICKET_ISSUED },
  { label: 'PAYMENT WAITING APPROVAL', value: ORDER_STATUS.PAYMENT_AWAITING_APPROVAL },
  { label: 'DELETED', value: ORDER_STATUS.DELETED },
];

export const TRAVELERS_KEYS = [
  {
    key: 'fullName',
    label: 'Traveler Name',
  },
  {
    key: TRAVELER_DETAIL_KEYS.TYPE,
    label: 'Type',
  },
  {
    key: TRAVELER_DETAIL_KEYS.DOB,
    label: 'Date of Birth',
  },
  {
    key: TRAVELER_DETAIL_KEYS.NATIONALITY,
    label: 'Nationality',
  },
  {
    key: TRAVELER_DETAIL_KEYS.PASSPORT_NUMBER,
    label: 'Passport Number',
  },
  {
    key: TRAVELER_DETAIL_KEYS.PASSPORT_EXPIRY,
    label: 'Passport Expiry',
  },
  {
    key: TRAVELER_DETAIL_KEYS.CNIC,
    label: 'CNIC',
  },
];

export const FLIGHTS_WITHOUT_MEAL = ['FZ', 'PC', 'G9', 'OD'];

export const PRE_REGISTRATION_OPTIONS = [
  'Get instant update once travel opens for this route.',
  'Get access to member only discounts and get notified when airlines launch sales.',
  'Extra convenience during your booking process.',
];

export const TIME_LIMITED_PAYMENT_METHODS = [
  {
    provider: 'JazzCash',
    name: 'JAZZ_MWALLET',
    maxMinutes: 180,
  },
  {
    provider: 'Easypaisa',
    name: 'EASYPAY_MA',
    maxMinutes: 180,
  },
];

export const FARE_OPTION_FLIGHTS = {
  AIRSIAL: 'PF',
  AIRBLUE: 'PA',
  SERENE_AIR: 'ER',
  PIA: 'PK',
};

export const BOOKING_TYPE = {
  FLIGHT: 'FLIGHT',
  INSURANCE: 'INSURANCE',
  TRANSFER: 'TRANSFER',
  HOTEL: 'HOTEL',
  TOUR: 'TOUR',
  BUS: 'BUS',
};

export const REFUND_CATEGORIES = {
  //Domestic and International
  'Flight Delay (Excess 8 Hours)': {
    label: 'Flight Delayed',
    className: 'delayed',
  },
  'Trip Cancellation': {
    label: 'Trip Cancelled',
    className: 'cancelled',
  },
  //domestic  only
  'Loss of Baggage': {
    label: 'Baggage Loss',
    className: 'baggage-loss',
  },
  'Loss of CNIC': {
    label: 'CNIC/Passport Loss',
    className: 'missed',
  },
  //international only
  'Baggage Delay (Excess 8 Hours)': {
    label: 'Baggage Loss',
    className: 'baggage-loss',
  },
  'Loss of CNIC / Passport': {
    label: 'CNIC/Passport Loss',
    className: 'missed',
  },
};

export const TOUR_GTM_EVENTS = {
  SUBMIT_SEARCH: 'toursSearchSubmit',
  MODIFY_SEARCH: 'toursModifySearch',
  SELECT_PACKAGE: 'toursSelectPackage',
  CONFIRM_PACKAGE: 'toursPackageConfirm',
  TRAVELER_DETAILS: 'toursTravellerDetail',
  PAYMENT_SUCCESS: 'toursPaymentSuccess',
};

export const TOUR_GTM_CATEGORIES = {
  SEARCH: 'toursSearch',
  SEARCH_RESULTS: 'toursSearchResults',
  BOOKING_REVIEW: 'toursBookingReview',
  PAYMENT: 'toursPayment',
};

export const ONLINE_CARD_PAYMENT_METHODS = {
  HBL_CS: 'HBL_CS',
  UBL_ETISALAT: 'UBL_ETISALAT',
  CARD: 'CARD',
  SIMPAISA: 'SIMPAISA',
};

export const ST_RBD_ONEWAY_ONLY_PROVIDERS = ['AIRSIAL'];

export const AIRLINES_WITH_DELAYED_TICKET = [FARE_OPTION_FLIGHTS.AIRSIAL, FARE_OPTION_FLIGHTS.SERENE_AIR];

export const ST_FC_RBD_ONEWAY_ONLY_PROVIDERS = [...ST_RBD_ONEWAY_ONLY_PROVIDERS, 'AIRBLUE', 'PIAAPI', 'SERENEAPI'];

export enum ST_RBDS {
  ST = 'ST',
  STFC = 'ST-FC',
}

export enum WebsiteVersion {
  V2 = 'v2',
}

export const PRODUCT_FILTERS = [
  { label: BOOKING_TYPE.FLIGHT, value: BOOKING_TYPE.FLIGHT },
  // { label: BOOKING_TYPE.HOTEL, value: BOOKING_TYPE.HOTEL },
  { label: BOOKING_TYPE.BUS, value: BOOKING_TYPE.BUS },
];

export const FLIGHT_TYPE_FILTERS = [
  { value: '', label: 'Show All' },
  { value: 'INT', label: 'International' },
  { value: 'DOM', label: 'Domestic' },
];

export const AFFILIATE_FILTERS = [
  { value: 'affiliate_hbl@sastaticket.pk', label: 'affiliate_hbl@sastaticket.pk' },
  { value: 'affiliate_st_hbl@sastaticket.pk', label: 'affiliate_st_hbl@sastaticket.pk' },
  { value: 'affiliate_st_app@sastaticket.pk', label: 'affiliate_st_app@sastaticket.pk' },
  { value: 'affiliate_ep_miniapp@sastaticket.pk', label: 'affiliate_ep_miniapp@sastaticket.pk' },
  { value: 'affiliate_wego@sastaticket.pk', label: 'affiliate_wego@sastaticket.pk' },
];
